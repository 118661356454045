<template>
    <div class="md-modal">
        <h1 class="reward__title">{{isUpdateMode ? $t('btSave') : $t('btAdd')}}</h1>
        <ap-form
                :fields="fields"
                :values="bundle"
                :buttons="buttons"
                @change="save"
                @close="$emit('close')"
        ></ap-form>
    </div>
</template>

<script>
    import ApForm from "../../../components/ApForm"
    const ages = {
        0: '4-6',
        1: '7-10',
        2: '11-15',
        3: '16+',
    };

    export default {
        name: "UpdateBundle",
        components: {ApForm},
        props: ['id'],
        computed: {
            // Form mode
            isUpdateMode() {
                // Return mode
                return this.id ? 1 : 0;
            },
            bundle() {
                if (this.id) {
                    let bundle = this.$get('bundles/get', this.id)
                    return bundle
                }
                return {}
            },
            fields() {
                let fields = {
                    title: {
                        type: 'string',
                        title: 'Title (Alias)',
                        validation: ["required"]
                    },
                    description: {
                        type: 'string',
                        title: 'Description (Alias)',
                        validation: ["required"]
                    },
                    full_description: {
                        type: 'string',
                        title: 'Full Description (Alias)',
                        validation: ["required"]
                    },
                    gender: {
                        type: 'checkbox',
                        title: 'Gender',
                        options: [
                            {
                                title: 'Female',
                                val: 'female'
                            },
                            {
                                title: 'Male',
                                val: 'male'
                            }
                        ],
                        validation: ["required"]
                    },
                    bundle_age: {
                        type: 'radio',
                        title: 'Bundle age',
                        options: [
                            {
                                title: '4-6',
                                val: Object.keys(ages)[0]
                            },
                            {
                                title: '7-10',
                                val: Object.keys(ages)[1]
                            },
                            {
                                title: '11-15',
                                val: Object.keys(ages)[2]
                            },
                            {
                                title: '16+',
                                val: Object.keys(ages)[3]
                            },
                        ],
                        validation: ["required"]
                    },
                    image: {
                        type: 'uploadPhoto',
                        title: 'Image',
                        validation: ["required"]
                    }
                }

                return fields
            },
            buttons() {
                // Buttons list
                let btns = {close: true};

                // Save button
                btns['save'] = {
                    title: this.isUpdateMode ? this.$t('btSave') : this.$t('btAdd'),
                    class: "md-primary md-raised",
                    type: "submit"
                }

                // Return list
                return btns;
            },
        },
        methods: {
            save(vals) {
                this.$dispatch('bundles/create', vals).then(() => {
                    this.$emit('close')
                })
            }
        }
    }
</script>

<style lang="scss" scoped>
    .md-modal {
        padding: 16px;
    }
</style>
