<template>
    <div class="bundles-list">
        <md-dialog
                :md-active.sync="showDialog"
                :md-fullscreen="false"
                class="popup-dialog"
        >
            <update-bundle
                    :id="bundle_id"
                    templateMode="true"
                    @close="showDialog=false"
            />
        </md-dialog>

        <md-table v-model="bundles">
            <md-table-toolbar>
                <h1 class="md-title">Bundles</h1>
            </md-table-toolbar>
            <md-table-row slot="md-table-row" slot-scope="{ item }">
                <md-table-cell md-label="ID" md-sort-by="id" md-numeric><a href="#" @click.prevent="update(item)">{{item.id}}</a></md-table-cell>
                <md-table-cell md-label="Image" md-sort-by="id"><img :src="$uploadedImageUrl(item.image)" width="100" height="100" alt=""></md-table-cell>
                <md-table-cell md-label="Title" md-sort-by="name">{{ item.title }}</md-table-cell>
                <md-table-cell md-label="Description" md-sort-by="name">{{ item.description }}</md-table-cell>
                <md-table-cell md-label="Age" md-sort-by="email">{{ ages[item.bundle_age] }}</md-table-cell>
                <md-table-cell md-label="Gender" md-sort-by="gender">{{ item.gender }}</md-table-cell>
                <md-table-cell md-label="Tasks count">{{ item.tasks_count }}</md-table-cell>
                <md-table-cell md-label="Action">
                    <md-button @click="deleteItem(item)">
                        <md-icon>delete_outline</md-icon>
                    </md-button>
                </md-table-cell>
            </md-table-row>
        </md-table>


        <md-button class="md-fab md-primary md-fab-bottom-right" @click="add">
            <md-icon>add</md-icon>
        </md-button>
    </div>
</template>

<script>
    import UpdateBundle from "./UpdateBundle";
    // eslint-disable-next-line no-unused-vars

    export default {
        name: "Bundles",
        data() {
            return {
                bundle_id: 0,
                showDialog: false,
                ages: {
                    0: '4-6',
                    1: '7-10',
                    2: '11-15',
                    3: '16+',
                }
            }
        },
        components: {UpdateBundle},
        computed: {
            bundles() {
                return this.$unreactGet('bundles/list');
            }
        },
        methods: {
            add() {
                this.bundle_id = 0
                this.showDialog = !this.showDialog
            },
            update(item) {
                this.bundle_id = item.id
                this.showDialog = true
            },
            deleteItem(item) {
                if (confirm(`Do you confirm to delete ID ${item.id}?`)) {
                    this.$dispatch('bundles/delete', item)
                }
            }
        }
    }
</script>

<style lang="scss" scoped>

    .bundles-list {
        width: 100%;
        min-height: 80vh;
    }

</style>
